import { createSlice } from "@reduxjs/toolkit";
import { getMemberResult, getResultByDay } from "../services/result.service";

const initialState = {
    dataSummary: null,
    summary: null,
    memberResult: [],
    loading: false,
    date: null,
    lottoMasterChecked: []
    // error: 
}

const resultSlice = createSlice({
    name: 'result',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setSummary: (state, action) => {
            state.summary = action.payload;
        },
        setDataSummary: (state, action) => {
            state.dataSummary = action.payload;
        },
        setMemberResult: (state, action) => {
            state.memberResult = action.payload;
        },
        setDate: (state, action) => {
            state.date = action.payload;
        },
        setLottoMasterChecked: (state, action) => {
            state.lottoMasterChecked = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResultByDay.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getResultByDay.fulfilled, (state, action) => {
                state.loading = false;
                state.dataSummary = { ...action.payload.data };
            })
            .addCase(getResultByDay.rejected, (state, action) => {                
                state.loading = false;
            })
            .addCase(getMemberResult.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getMemberResult.fulfilled, (state, action) => {
                state.loading = false;
                state.memberResult = action.payload.data;
            })
            .addCase(getMemberResult.rejected, (state, action) => {
                state.loading = false;
            })
    }
});

export const { setLoading, setSummary, setDataSummary, setMemberResult, setDate, setLottoMasterChecked } = resultSlice.actions;
export default resultSlice.reducer;