import { createSlice } from "@reduxjs/toolkit";
import { Profile } from "../services/main.services";

const initialState = {
    isLoadSuccess: false,
    credit: 0,
    profile: null,
    loading: false,
    // error: 
}

const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        setIsLoadSuccess: (state, action) => {
            state.isLoadSuccess = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(Profile.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(Profile.fulfilled, (state, action) => {
                state.loading = false;
                // const newData = { ...action.payload.data };                
                state.profile = { ...action.payload.data };
                state.credit = action.payload.data.credit;
            })
            .addCase(Profile.rejected, (state, action) => {
                state.loading = false;
                // state.error = action.payload;
            })
    }
});

export const { setIsLoadSuccess } = mainSlice.actions;
export default mainSlice.reducer;