import { MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Radio, RadioChangeEvent } from 'antd';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
    menu: string,
    type: string
}

const getItems = (role: string | null) => {
    const items = [
        {
            key: 'bet-report',
            label: 'รายการแทง',
            route: 'bet-report'
        },
        {
            key: 'rules',
            label: 'กติกาการเล่น',
            route: 'rules'
        },
        {
            key: 'member',
            label: 'จัดการสมาชิก',
            route: 'member'
        },
        {
            key: 'result',
            label: 'สรุปยอด',
            route: 'result'
        },
    ];

    if (role === 'header') {
        items.push({
            key: 'agent',
            label: 'จัดการเอเย่น',
            route: 'agent'
        })
    }

    if (role === 'super-admin') {
        items.push({
            key: 'setting',
            label: 'ตั้งค่า',
            route: 'setting'
        },
        {
            key: 'admin',
            label: 'จัดการเฮดเดอร์',
            route: 'admin'
        },
        {
            key: 'announce',
            label: 'ออกผล',
            route: 'announce'
        });
    }

    items.push({
        key: 'lotto-result',
        label: 'ตรวจสอบผลรางวัล',
        route: 'lotto-result'
    })

    return items;
}

const MenuNav: FC<Props> = ({ menu, type }) => {
    let history = useHistory();
    const role = localStorage.getItem("role");

    const goRoute = (type: string, e: RadioChangeEvent | any) => {
        let menuName = '/';
        if (type === 'radio') {
            menuName = '/' + e.target.value;
            if (menuName === '/bet') menuName = '/';
        } else {
            menuName = '/' + e.key;
            if (menuName === '/bet') menuName = '/';
        }

        // console.log(history.location);

        // window.location.href = menuName;
        history.push(menuName);
        // history
    }

    if (type === 'radio') {
        return (
            <Radio.Group defaultValue={menu} buttonStyle="solid" style={{ marginLeft: 20, borderRadius: 8 }} onChange={(e) => goRoute('radio', e)}>
                <Radio.Button value="bet-report">รายการแทง</Radio.Button>
                <Radio.Button value="rules">กติกาการเล่น</Radio.Button>
                <Radio.Button value="member">จัดการสมาชิก</Radio.Button>
                {role === 'super-admin' ? <Radio.Button value="admin">จัดการเฮดเดอร์</Radio.Button> : <></>}
                {role === 'header' ? <Radio.Button value="agent">จัดการเอเย่น</Radio.Button> : <></>}
                <Radio.Button value="result">บัญชีการเงิน</Radio.Button>
                {role === 'super-admin' ? <Radio.Button value="setting">ตั้งค่า</Radio.Button> : <></>}
                {role === 'super-admin' ? <Radio.Button value="announce">ออกผล</Radio.Button> : <></>}
                <Radio.Button value="lotto-result">ตรวจสอบผลรางวัล</Radio.Button>
            </Radio.Group>
        )
    }

    return (<Dropdown overlay={<Menu items={getItems(role)} onClick={(e) => goRoute('dropdown', e)} />} placement="bottomLeft">
        <Button style={{ float: 'right', marginRight: 10, marginTop: 15 }}>
            <MenuOutlined />
        </Button>
    </Dropdown>)

}

export default MenuNav;