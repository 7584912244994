import { HomeOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Form, Image, Input, Row, Space, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Notification from '../../components/notification/notification';
import { login } from '../../services/services';
import './login.css';
import spaceLotLogo from '../../assets/images/spacelot_logo.png';

const Login: FC = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    let history = useHistory();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) history.push("/");

    }, [history]);

    const onLogin = async (values: any) => {
        setLoading(true);
        const { username, password } = values;

        try {
            const response = await login(username, password);
            // console.log(response);
            if (response.status === 200) {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('name', response.data.nickname);
                localStorage.setItem('memberId', response.data.id);
                localStorage.setItem('role', response.data.rank.name);
                localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
                setLoading(true);
                history.push("/");
            } else {
                setLoading(false);
                Notification('error', 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
            }
        } catch (error) {
            setLoading(false);
            Notification('error', 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
        }
    };

    return (
        <Spin spinning={loading}>
            <Row justify="center" className="bg">
                <Form
                    form={form}
                    style={{ minWidth: 420 }}
                    className="card"
                    name="signin"
                    onFinish={onLogin}
                >
                    <Card>
                        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', paddingBottom: 10 }}>
                            <Image src={spaceLotLogo} preview={false} width={380} height={200} />
                        </Space>
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณาใส่ชื่อผู้ใช้',
                                },
                            ]}
                        >
                            <Input className="real-input" size="large" maxLength={32} prefix={<UserOutlined style={{ color: 'white' }} />} placeholder="ชื่อผู้ใช้" />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณาใส่รหัสผ่าน',
                                },
                            ]}
                        >
                            <Input className="real-input" size="large" maxLength={32} prefix={<LockOutlined style={{ color: 'white' }} />} type="password" placeholder="รหัสผ่าน" />
                        </Form.Item>

                        <div className="footer">
                            <Button block={true} className="btn" htmlType={'submit'}>
                                เข้าสู่ระบบ
                            </Button>
                        </div>
                    </Card>
                </Form>
            </Row>
        </Spin>
    )
}

export default Login;