import { Col, Row, Spin, Typography } from 'antd';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';
import MainLayout from "../../components/main-layout/main-layout";
import "../main/main.css";
import 'moment/locale/th';
import { getLottoMaster, logout } from '../../services/services';
import { LottoMaster, PeriodType } from '../../types/period.type';
import { useHistory } from 'react-router-dom';
import Notification from '../../components/notification/notification';
import LottoMasterCard from '../../components/lotto-master-card/lotto-master-card';

const { Title, Text, Link } = Typography;

const LottoResult: FC = () => {
    let history = useHistory();
    const token = localStorage.getItem("token");
    moment.locale('th');
    const [loading, setLoading] = useState(true);
    const [lottoMaster, setLottoMaster] = useState(null);

    const lottoMasterData = useCallback(async () => {
        const response = await getLottoMaster();
        setLottoMaster(response.data);
    }, []);

    useEffect(() => {
        if (!token) {
            localStorage.clear();
            return history.push('/login');
        }
        lottoMasterData()
            .then(() => setLoading(false))
            .catch((v) => {
                setLoading(false);
                logout();
                return history.push('/login');
            });
    }, [lottoMasterData]);

    const goLink = (period: PeriodType) => {
        if (period.status === 'inactive') {
            Notification('error', period.lottoMaster?.name + 'ปิดรับแล้ว', 'แจ้งเตือนจากระบบ');
            return '#'
        }

        return history.push({
            pathname: '/bet',
            state: {
                period
            }
        })
    }

    const renderLottoMaster = () => {
        if (!lottoMaster) return <></>;
        const data: LottoMaster[] = lottoMaster['data'];

        return data.map((lotto: LottoMaster, index: number) => {
            return <Col span={6} md={6} sm={6} xs={24}>
                {/* <Link onClick={() => goLink(period)}> */}
                <LottoMasterCard key={index} lottoMaster={lotto} />
                {/* </Link> */}
            </Col>
        });
    }

    return (
        <Spin spinning={loading}>
            <MainLayout menu="lotto-result">
                <Title>ตรวจสอบผลรางวัล</Title>
                <div className="lotto-section">
                    <Title level={4}>หวยลาว</Title>
                    <Row gutter={[8, 8]}>
                        {lottoMaster ? renderLottoMaster() : <></>}
                    </Row>
                </div>
            </MainLayout>
        </Spin>
    );


}

export default LottoResult;