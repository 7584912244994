import { createSlice } from "@reduxjs/toolkit";
import { getClientResult } from "../services/client.services";

const initialState = {
    dataClientSummary: null,
    clientSummary: null,
    clientResult: [],
    loading: false,
    date: null
    // error: 
}

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setClientSummary: (state, action) => {
            state.clientSummary = action.payload;
        },
        setDataClientSummary: (state, action) => {
            state.dataClientSummary = action.payload;
        },
        setClientResult: (state, action) => {
            state.clientResult = action.payload;
        },
        setDate: (state, action) => {
            state.date = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getClientResult.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getClientResult.fulfilled, (state, action) => {
                state.loading = false;
                state.clientResult = action.payload.data;
            })
            .addCase(getClientResult.rejected, (state, action) => {
                state.loading = false;
            })
    }
});

export const { setLoading, setClientSummary, setDataClientSummary, setClientResult, setDate } = clientSlice.actions;
export default clientSlice.reducer;