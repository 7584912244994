import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Empty, Form, Input, Modal, Select, Space, Spin, Table, Tag, Typography } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MainLayout from "../../components/main-layout/main-layout";
import Notification from "../../components/notification/notification";
import { createMember, deleteMember, getAllMembers, getProfile, logout, updateCredit, updateMember } from "../../services/services";
import { MemberType } from "./member.type";
import { utils } from "../../services/utils";
import { useDispatch, useSelector } from "react-redux";
import { Profile } from "../../services/main.services";

const { Title } = Typography;
const { Option } = Select;

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};

const memberColumns: any = (onEditMember: Function, onConfirmDeleteMember: Function, openAddCreditModal: Function) => {
    const { role } = utils.getRoleAndPermissions();
    const columns = [
        {
            title: 'ลำดับ',
            dataIndex: 'id',
            key: 'id',
            // fixed: 'left',
            // width: 70,
            render: (text: string, record: MemberType, index: number) => {
                return <>{index + 1}</>
            }
        },
        {
            title: 'ชื่อเล่น',
            dataIndex: 'nickname',
            key: 'nickname',
            // fixed: 'left',
            // width: 100,
        },
        {
            title: 'ชื่อผู้ใช้ในระบบ',
            dataIndex: 'username',
            key: 'username',
            // width: 150,
        },
        {
            title: 'ตำแหน่ง',
            dataIndex: 'rank',
            key: 'rank',
            align: 'center',
            render: (text: string, record: MemberType, index: number) => {
                if (record.rank?.name === 'header') return <Tag color={'geekblue'} key={text}>ผู้ดูแลระบบ</Tag>;
                if (record.rank?.name === 'member') return <Tag color={'green'} key={text}>สมาชิก</Tag>;
            }
        },
        {
            title: 'เครดิต (บาท)',
            dataIndex: 'credit',
            key: 'credit',
            align: 'right',
            render: (text: string, record: MemberType, index: number) => {
                return (
                    <Space>
                        {utils.numberWithCommas(text)}
                        <Button size="small" type="primary" danger onClick={() => openAddCreditModal(record)}>เติม</Button>
                    </Space>
                );
            }
        },
        {
            title: 'จัดการ',
            key: 'action',
            align: 'center',
            fixed: 'right',
            render: (text: string, record: MemberType, index: number) => (
                <Space size="middle">
                    <Button size="small" type="primary" onClick={() => onEditMember(record)}>แก้ไข</Button>
                    <Button size="small" danger onClick={() => onConfirmDeleteMember(record)}>ลบ</Button>
                </Space>
            ),
        },
    ];

    if (role === 'super-admin') {
        const column = {
            title: 'ผู้สร้าง',
            dataIndex: 'grant',
            key: 'grant',
            align: 'center',
            render: (text: string, record: MemberType, index: number) => {
                return <Tag color={record.grant ? 'purple' : 'red'}>{record.grant ? record.grant.nickname : 'ไม่มีผู้สร้าง'}</Tag>;
            }
        };

        columns.splice(columns.length - 1, 0, column);
    }

    return columns;
}

const Member: FC = () => {
    let history = useHistory();
    const dispatch = useDispatch<any>();
    // form
    const [formAdd] = Form.useForm();
    const [formEdit] = Form.useForm();
    const [formCredit] = Form.useForm();

    const [loading, setLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [members, setMembers] = useState<any>(null);
    const [memberSelected, setMemberSelected] = useState<any>(null);
    const [addMemberVisible, setAddMemberVisible] = useState(false);
    const [editMemberVisible, setEditMemberVisible] = useState(false);
    const [deleteMemberVisible, setDeleteMemberVisible] = useState(false);
    const [rankSelected, setRankSelected] = useState('');
    const { role } = utils.getRoleAndPermissions();
    const [addCreditMemberVisible, setAddCreditMemberVisible] = useState(false);

    const { credit } = useSelector((state: any) => {
        return {
            credit: state.main.credit
        }
    });

    const getMembers = useCallback(async () => {
        let filter = '';

        if (role === 'header' || role === 'agent') {
            filter += 'filter=grant.id||$eq||' + localStorage.getItem('memberId')
        }
        filter += '&filter=rank.id||$eq||2'

        const response = await getAllMembers(filter);
        setMembers(response.data);
    }, []);

    useEffect(() => {

        getMembers().then(() => setLoading(false)).catch(() => {
            setLoading(false);
            logout();
            return history.push('/login');
        });

        dispatch(Profile());
    }, [getMembers, getProfile, dispatch]);

    const onAddMember = async () => {
        formAdd.resetFields();
        setAddMemberVisible(true);
    }

    const onEditMember = async (member: MemberType) => {
        formEdit.resetFields();
        formEdit.setFieldsValue({
            nickname: member.nickname,
            username: member.username,
            rank: member.rank?.id + ""
        });
        setRankSelected(member.rank?.id + "");
        setMemberSelected(member);
        setEditMemberVisible(true);
    }

    const onConfirmDeleteMember = (member: MemberType) => {
        setMemberSelected(member);
        setDeleteMemberVisible(true);
    }

    const onConfirmAdd = () => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการบันทึกข้อมูลใช่หรือไม่?',
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: () => onSaveNewMember()
        });
    }

    const onSaveNewMember = async () => {
        setLoadingForm(true);
        formAdd.validateFields().then(async (value) => {
            let data = formAdd.getFieldsValue();
            data["rank"] = parseInt(data["rank"]);
            data["grant"] = parseInt(localStorage.getItem('memberId') || '0');
            data["credit"] = parseInt(data["credit"]);

            const response = await createMember(data);
            if (response.status === 201) {
                await getMembers();
                dispatch(Profile());
                Notification('success', 'สร้างสมาชิกสำเร็จ');
            } else {
                Notification('error', 'สร้างสมาชิกไม่สำเร็จ');
            }
            setAddMemberVisible(false);
            setLoadingForm(false);
        }).catch((reason) => {
            setLoadingForm(false);
            const response = reason.response;
            if (response.data.message === "insufficient credit") {
                return Notification('error', 'สร้างสมาชิกไม่สำเร็จ เครดิตคงเหลือไม่เพียงพอ');
            }
            
            return Notification('error', 'สร้างสมาชิกไม่สำเร็จ' + reason);
        });
    }

    const onChangeRank = (e: any) => {
        setRankSelected(e);
    }

    const membersData = () => {
        if (!members) return [];

        const data: MemberType[] = members['data'];
        return data;
    }

    const onConfirmEdit = () => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการบันทึกข้อมูลใช่หรือไม่?',
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: () => onEditSave()
        });
    }

    const onEditSave = async () => {
        setLoadingForm(true);
        formEdit.validateFields().then(async (value) => {
            const data = formEdit.getFieldsValue();

            if (data.password === "") delete data.password;
            if (data.rank) data.rank = parseInt(data.rank);

            const response = await updateMember(memberSelected.id, data);
            if (response.status === 200) {
                await getMembers();
                Notification('success', 'แก้ไขสมาชิกสำเร็จ');
            } else {
                Notification('error', 'แก้ไขสมาชิกไม่สำเร็จ');
            }
            setEditMemberVisible(false);
            setLoadingForm(false);
        }).catch((reason) => {
            Notification('error', 'สร้างสมาชิกไม่สำเร็จ' + reason);
            setLoadingForm(false);
        });
    }

    const onDeleteMember = async () => {
        setLoadingForm(true);
        const response = await deleteMember(memberSelected.id);
        if (response.status === 200) {
            await getMembers();
            Notification('success', 'ลบสมาชิกสำเร็จ');
        } else {
            Notification('error', 'ลบสมาชิกไม่สำเร็จ');
        }
        setDeleteMemberVisible(false);
        setLoadingForm(false);
    }

    const openAddCreditModal = (member: MemberType) => {
        formCredit.resetFields();
        setMemberSelected(member);
        setAddCreditMemberVisible(true);
    }

    const onUpdateCredit = async () => {
        setLoadingForm(true);
        formCredit.validateFields().then(async (value) => {
            const data = formCredit.getFieldsValue();

            const response = await updateCredit(memberSelected.id, parseInt(data.credit));
            if (response.status === 200) {
                await getMembers();
                dispatch(Profile());

                Notification('success', 'เพิ่มเครดิตสำเร็จ');
            } else {
                Notification('error', 'เพิ่มเครดิตไม่สำเร็จ');
            }
            setAddCreditMemberVisible(false);
            setLoadingForm(false);
        }).catch((reason) => {
            Notification('error', 'เพิ่มเครดิตไม่สำเร็จ' + reason);
            setLoadingForm(false);
        });

    }

    return (
        <MainLayout menu="member">
            <Spin spinning={loading}>
                <Modal
                    title={<Title level={5}>เพิ่มสมาชิก</Title>}
                    style={{ top: 25 }}
                    visible={addMemberVisible}
                    onOk={() => onConfirmAdd()}
                    okText="บันทึก"
                    onCancel={() => setAddMemberVisible(false)}
                    cancelText="ยกเลิก"
                    width={1000}
                >
                    <Spin spinning={loadingForm}>
                        <Form {...layout} form={formAdd}>
                            <Form.Item
                                name="nickname"
                                label="ชื่อเล่น"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please fill nickname'
                                    },
                                ]}
                            >
                                <Input placeholder="ระบุชื่อเล่น" />
                            </Form.Item>
                            <Form.Item
                                name="username"
                                label="ชื่อผู้ใช้"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please fill username'
                                    },
                                ]}
                            >
                                <Input placeholder="ระบุชื่อผู้ใช้" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="รหัสผ่าน"
                                rules={[{ required: true }]}
                            >
                                <Input type={'password'} placeholder="รหัสผ่าน" />
                            </Form.Item>
                            <Form.Item name="rank" label="ตำแหน่ง" rules={[{ required: true }]}>
                                <Select
                                    placeholder="เลือกตำแหน่ง"
                                    onChange={(e) => onChangeRank(e)}
                                >
                                    {/* <Option value="1">ผู้ดูแล</Option> */}
                                    <Option value="2">สมาชิก</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="credit"
                                label="เครดิต"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please fill credit'
                                    },
                                ]}
                            >
                                <Input type="number" placeholder="ระบุเงินเครดิต" />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
                <Modal
                    title={<Title level={5}>แก้ไขข้อมูลสมาชิก</Title>}
                    style={{ top: 25 }}
                    visible={editMemberVisible}
                    onOk={() => onConfirmEdit()}
                    okText="แก้ไขสมาชิก"
                    onCancel={() => setEditMemberVisible(false)}
                    cancelText="ยกเลิก"
                    width={1000}
                >
                    {memberSelected ?
                        <Spin spinning={loadingForm}>
                            <Form {...layout} form={formEdit}>
                                <Form.Item
                                    name="nickname"
                                    label="ชื่อเล่น"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please fill nickname'
                                        },
                                    ]}
                                >
                                    <Input placeholder="ระบุชื่อเล่น" />
                                </Form.Item>
                                <Form.Item
                                    name="username"
                                    label="ชื่อผู้ใช้"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please fill username'
                                        },
                                    ]}
                                >
                                    <Input placeholder="ระบุชื่อผู้ใช้" />
                                </Form.Item>
                                <Form.Item name="rank" label="ตำแหน่ง" rules={[{ required: true }]}>
                                    <Select
                                        placeholder="เลือกตำแหน่ง"
                                        onChange={(e) => onChangeRank(e)}
                                    >
                                        {/* <Option value="1">ผู้ดูแล</Option> */}
                                        <Option value="2">สมาชิก</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label="รหัสผ่าน"
                                >
                                    <Input type={'password'} placeholder="กรอกรหัสผ่านเมื่อต้องการเปลี่ยน" />
                                </Form.Item>
                            </Form>
                        </Spin>
                        : <></>}
                </Modal>
                <Modal
                    title={<Title level={5}>ลบสมาชิก</Title>}
                    style={{ top: 25 }}
                    visible={deleteMemberVisible}
                    onOk={() => onDeleteMember()}
                    okText="ลบสมาชิก"
                    onCancel={() => setDeleteMemberVisible(false)}
                    cancelText="ยกเลิก"
                    width={1000}
                >
                    <Spin spinning={loadingForm}>
                        <Title level={5}>ต้องการลบ {memberSelected ? memberSelected.nickname : ''} ใช่หรือไม่?</Title>
                    </Spin>
                </Modal>
                <Modal
                    title={<Title level={5}>เพิ่มเครดิต</Title>}
                    open={addCreditMemberVisible}
                    maskClosable={false}
                    onOk={() => onUpdateCredit()}
                    okText="ยันยืน"
                    onCancel={() => setAddCreditMemberVisible(false)}
                    cancelText="ยกเลิก"
                >
                    <Spin spinning={loadingForm}>
                        <Typography.Text strong type="success">เครดิตคงเหลือ: {utils.numberWithCommas(credit + "")}</Typography.Text>
                        <Form form={formCredit}>
                            <Form.Item
                                name="credit"
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาระบุเครดิต'
                                    },
                                    {
                                        message: 'เครดิตห้ามต่ำกว่า 1',
                                        validator: (_, value) => {
                                            if (parseInt(value) > 0) return Promise.resolve();

                                            return Promise.reject('เครดิตห้ามต่ำกว่า 1');
                                        }
                                    }
                                ]}
                            >
                                <Input placeholder="ระบุเครดิตที่เติม" type="number" />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
                <Title level={4}>สมาชิก</Title>
                <Button type="primary" onClick={() => onAddMember()}>เพิ่มสมาชิก</Button>
                <Table style={{ marginTop: 10 }} size="small" columns={memberColumns(onEditMember, onConfirmDeleteMember, openAddCreditModal)} dataSource={membersData()} scroll={{ x: 1100 }} pagination={{ defaultPageSize: 50, position: ['bottomRight'] }} locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} />
            </Spin>
        </MainLayout>
    )
}

export default Member;