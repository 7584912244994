import { FC, useEffect, useState } from "react";
import MainLayout from "../../../components/main-layout/main-layout";
import { Button, Divider, Empty, PageHeader, Spin, Table, Typography } from "antd";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { utils } from "../../../services/utils";
import { useDispatch, useSelector } from "react-redux";
import { ResultType } from "antd/lib/result";
import { getClientResult } from "../../../services/client.services";
import Notification from "../../../components/notification/notification";
import moment from "moment";

const { Title } = Typography;

const renderMemberResultColumns = (onShowMemberPeriodDetail: Function, onShowClient: Function, data: any) => {
    const columns: ColumnsType<any> = [
        {
            title: <b>ลำดับ</b>,
            dataIndex: 'id',
            key: 'id',
            width: 100,
            align: 'center',
            render: (text: string, record: any, index: number) => {
                return index + 1
            }
        },
        {
            title: <b>สมาชิก</b>,
            dataIndex: 'nickname',
            key: 'nickname',
            width: 400,
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                return record.hasChild ? <Typography.Link onClick={() => onShowClient(record)}>{text}</Typography.Link> : text;
            }
        },
        {
            title: <b>ระดับ</b>,
            dataIndex: 'rank',
            key: 'rank',
            width: 100,
            render: (text: string, record: any, index: number) => {
                return <b>{text === 'header' ? 'เฮดเดอร์' : text === 'agent' ? 'เอเย่น' : 'เมมเบอร์'}</b>;
            }
        },
        {
            title: <b>ยอดทั้งหมด</b>,
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                return <span style={{ fontSize: 16, color: 'green' }}>{utils.numberWithCommas(text ? text : "0")}</span>
            }
        },
        {
            title: <b>ค่าคอมมิชชั่น</b>,
            dataIndex: 'commission',
            key: 'commission',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                return <span style={{ fontSize: 16, color: '#7cb305' }}>{utils.numberWithCommas(text ? text : "0")}</span>
            }
        },
        {
            title: <b>ยอดจ่าย</b>,
            dataIndex: 'pay',
            key: 'pay',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                return <span style={{ fontSize: 16, color: '#d4380d' }}>{utils.numberWithCommas(text ? text : "0")}</span>
            }
        },
        {
            title: <b>คงเหลือ</b>,
            dataIndex: 'net',
            key: 'net',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                const sum = parseFloat(record.total) - (parseFloat(record.pay) + parseFloat(record.commission));
                return <span style={{ fontSize: 16, color: '#c41d7f' }}>{utils.numberWithCommas(sum ? sum + "" : "0")}</span>
            }
        },
        // {
        //     title: <b>รายการแทง</b>,
        //     dataIndex: 'action',
        //     key: 'action',
        //     align: 'center',
        //     render: (text: string, record: any, index: number) => {
        //         return <Button type="link" onClick={() => onShowMemberPeriodDetail(record)}>รายการแทง</Button>
        //     }
        // },
    ];

    columns.push({
        title: <b>หุ้นสุทธิ</b>,
        dataIndex: 'share',
        key: 'share',
        align: 'right',
        render: (text: string, record: any, index: number) => {

            const total = ((record.total - record.pay) * (record.share / 100.00)) * 1.00;

            return total ? <span style={{ fontSize: 16, color: '#4547FF' }}>{utils.numberWithCommas(total ? (total).toString() : "0")}</span> : <span style={{ fontSize: 16, color: '#4547FF' }}>{utils.numberWithCommas("0")}</span>;

            // if (record.rank === 'member') return <span style={{ fontSize: 16, color: '#4547FF' }}>{utils.numberWithCommas("0")}</span>;

            // if (record.pay) {
            //     if (record.pay !== 0) {
            //         const total = record.pay - (record.pay * ((data.share - record.share) / 100.00));
            //         return <span style={{ fontSize: 16, color: '#4547FF' }}>{utils.numberWithCommas(total ? (total).toString() : "0")}</span>
            //     }

            //     const total = (record.total * ((data.share - record.share) / 100.00));
            //     return <span style={{ fontSize: 16, color: '#4547FF' }}>{utils.numberWithCommas(total ? (total).toString() : "0")}</span>

            // }

            // return <span style={{ fontSize: 16, color: '#4547FF' }}>{utils.numberWithCommas("0")}</span>
        }
    }, {
        title: <b>ส่วนแบ่งที่ได้รับ</b>,
        dataIndex: 'share',
        key: 'share',
        align: 'right',
        render: (text: string, record: any, index: number) => {


            const totalShare = ((record.total - record.pay) * (record.share / 100.00)) * 1.00;
            // total - (com + pay + share) 
            const total = record.total - (record.commission + record.pay + totalShare);

            return total ? <span style={{ fontSize: 16, color: '#d4380d' }}>{utils.numberWithCommas(total ? (total).toString() : "0")}</span> : <span style={{ fontSize: 16, color: '#d4380d' }}>{utils.numberWithCommas("0")}</span>;

            // if (record.rank === 'member') return <span style={{ fontSize: 16, color: '#d4380d' }}>{utils.numberWithCommas(record.commission ? record.commission : "0")}</span>

            // if (record.pay) {
            //     if (record.pay !== 0) {
            //         const total = record.commission + (record.pay - (record.pay * (record.share / 100.00)));
            //         return <span style={{ fontSize: 16, color: '#d4380d' }}>{utils.numberWithCommas(total ? (total).toString() : "0")}</span>
            //     }

            //     const total = record.commission + (record.total * (record.share / 100.00));
            //     return <span style={{ fontSize: 16, color: '#d4380d' }}>{utils.numberWithCommas(total ? (total).toString() : "0")}</span>

            // }

            // return <span style={{ fontSize: 16, color: '#d4380d' }}>{utils.numberWithCommas("0")}</span>
        }
    });


    columns.push({
        title: <b>รายการแทง</b>,
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (text: string, record: any, index: number) => {
            return <Button type="link" onClick={() => onShowMemberPeriodDetail(record)}>รายการแทง</Button>
        }
    });

    return columns;
}

const Client: FC = (props: any) => {
    let history = useHistory();
    const dispatch = useDispatch<any>();
    const data = props.location.state?.data;
    const { role, permissions } = utils.getRoleAndPermissions();

    const { dataSummary, dataClientSummary, clientResult, loading, lottoMasterChecked, date } = useSelector((state: any) => {
        return {
            dataClientSummary: state.client.dataClientSummary,
            clientResult: state.client.clientResult,
            loading: state.client.loading,
            lottoMasterChecked: state.result.lottoMasterChecked,
            date: state.result.date,
            dataSummary: state.result.dataSummary,
        }
    });

    useEffect(() => {
        searchMemberResult('between');
    }, [data]);

    const searchMemberResult = async (type: string) => {
        try {
            let filter = '?startDate=' + date[0].format('YYYY-MM-DD 00:00:00');
            filter += '&endDate=' + date[1].format('YYYY-MM-DD 23:59:59');
            filter += '&type=' + type;
            filter += '&id=' + data.id;
            if (lottoMasterChecked.length > 0)
                filter += '&lottoMaster=' + lottoMasterChecked.join(",");

            await dispatch(getClientResult({ filter }));
        } catch (error) {
            return Notification('error', 'แจ้งเตือน', 'เกิดข้อผิดพลาดเล็กน้อย');
        }
    }

    const onShowMemberPeriodDetail = (data: any) => {
        const dataSummaryTemp = [...dataSummary.data];

        const memberBills: any = [];
        dataSummaryTemp.forEach((v: any) => {
            const period = { ...v };
            delete period.bills;

            // let billsFilter = v.bills.filter((bill: any) => bill.member.id === data.id);
            v.bills.forEach((bill: any) => {
                if (data.rank === 'member') {
                    if (data.id === bill.member.id) {
                        memberBills.push({
                            ...bill,
                            period
                        })
                    }
                }
            });
        });

        return history.push({
            pathname: '/result/bill-member',
            state: {
                memberBills,
                member: data
            }
        })
    }

    const onShowClient = (record: any) => {
        return history.push({
            pathname: '/result/client',
            state: {
                data: record
            }
        })
    }

    const renderMemberResultSummary = (memberSummary: any) => {
        if (memberSummary.length === 0) return <></>;

        // console.log(dataSummary);
        let total = 0;
        let commission = 0;
        let pay = 0;
        let share = 0;
        let netShare = 0;
        let net = 0;

        memberSummary.forEach((v: any) => {
            total += v.total;
            commission += v.commission;
            pay += v.pay;

            const shareCalculate = ((v.total - v.pay) * (v.share / 100.00)) * 1.00;
            share += shareCalculate;
            netShare += v.total - (v.commission + v.pay + shareCalculate);
            net += v.total - (v.commission + v.pay);
        });

        return (
            <Table.Summary>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0} align="center" colSpan={3}>
                        <span style={{ fontWeight: 'bold' }}>รวม</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#389e0d' }}>{utils.numberWithCommas(total ? total.toString() : "0")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#7cb305' }}>{utils.numberWithCommas(commission ? commission.toString() : "0")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#d4380d' }}>{utils.numberWithCommas(pay ? pay.toString() : "0")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#c41d7f' }}>{utils.numberWithCommas(net ? net.toString() : "0")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#4547FF' }}>{utils.numberWithCommas(share ? share.toString() : "0")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#d4380d' }}>{utils.numberWithCommas(netShare ? netShare.toString() : "0")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                    </Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>
        )
    }

    return (
        <MainLayout menu="result/client">
            <Spin spinning={loading}>
                <PageHeader
                    style={{ padding: 0, marginBottom: 10 }}
                    title={<Button style={{ borderRadius: 20 }} type={'primary'} onClick={() => history.goBack()}><ArrowLeftOutlined /> กลับหน้าสรุปผล</Button>}
                />

                <Title level={4}>สรุปยอดแบบรายสมาชิกของ {data.nickname}</Title>
                <Table size="small" columns={renderMemberResultColumns(onShowMemberPeriodDetail, onShowClient, data)} dataSource={clientResult} locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} pagination={{ defaultPageSize: 100 }} summary={(memberResult: any) => renderMemberResultSummary(memberResult)} />
                <Divider className="divider-inside" />



            </Spin>
        </MainLayout>
    )
}

export default Client;