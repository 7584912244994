import { Button, Divider, Empty, Form, Input, Modal, PageHeader, Slider, Space, Spin, Switch, Table, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MainLayout from "../../components/main-layout/main-layout";
import { getLottoMaster, getSetting, logout, updateLottoMaster, updateSetting } from "../../services/services";
import Notification from "../../components/notification/notification";
import { utils } from "../../services/utils";
import { LottoMaster } from "../../types/period.type";
import { ColumnsType } from "antd/lib/table";
import { SettingType } from "../../types/setting.type";
// import { UilEdit, UilTrashAlt, UilExclamationTriangle } from '@iconscout/react-unicons';

const { Title, Text, Paragraph } = Typography;

// const formatter = (value: number | undefined) => {
//     return `${value}%`;
// }

const settingColumns = (onEdit = (record: SettingType) => { }, onConfirmDelete = (record: SettingType) => { }) => {
    const columns = [
        {
            title: <b>ลำดับ</b>,
            dataIndex: 'id',
            key: 'id',
            render: (text: string, record: SettingType, index: number) => {
                return index + 1;
            }
        },
        {
            title: <b>คีย์ข้อมูล</b>,
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: <b>ค่าของคีย์ข้อมูล</b>,
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: <b>ความหมาย</b>,
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: <b>จัดการ</b>,
            dataIndex: 'action',
            key: 'action',
            render: (text: string, record: SettingType, index: number) => {
                return (
                    <Space>
                        <Button type="primary" onClick={(() => onEdit(record))}>
                            แก้ไข
                            {/* <UilEdit /> */}
                        </Button>
                        {/* <Button className="btn-icon" type="primary" danger onClick={() => onConfirmDelete(record)}> */}
                            {/* ลบ */}
                            {/* <UilTrashAlt /> */}
                        {/* </Button> */}
                    </Space>
                );
            }
        },
    ];

    return columns;
}

const Setting = () => {
    const [formEdit] = Form.useForm();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [lottoMaster, setLottoMaster] = useState([]);
    const { role, permissions } = utils.getRoleAndPermissions();
    const [setting, setSetting] = useState([]);
    // const [openAddNewSettingModal, setOpenAddNewSettingModal] = useState(false);
    const [openEditSettingModal, setOpenEditSettingModal] = useState(false);
    const [currentSetting, setCurrentSetting] = useState<SettingType|null>(null);

    const getAllLottoMaster = useCallback(async () => {
        const response = await getLottoMaster();
        setLottoMaster(response.data.data);
    }, []);

    const getAllSetting = useCallback(async () => {
        const response = await getSetting();
        setSetting(response.data.data);
    }, []);

    useEffect(() => {

        if (role !== 'super-admin') return history.push('/login');

        setLoading(true);
        getAllLottoMaster().then(() => setLoading(false)).catch((v) => {
            setLoading(false);
            logout();
            return history.push('/login');
        });

        getAllSetting().then(() => setLoading(false)).catch((v) => {
            setLoading(false);
            logout();
            return history.push('/login');
        });
    }, [getAllLottoMaster, getAllSetting]);

    const onEditSetting = (record: SettingType) => {
        formEdit.resetFields();
        formEdit.setFieldsValue(record);
        setCurrentSetting(record);
        setOpenEditSettingModal(true);
    }

    const onConfirmEditSetting = () => {
        Modal.confirm({
            title: 'Confirm',
            // icon: <UilExclamationTriangle />,
            content: 'ต้องการบันทึกข้อมูลใช่หรือไม่?',
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: () => onSaveEditSetting()
        });
    }

    const onSaveEditSetting = () => {
        formEdit.validateFields().then(async (values) => {
          try {
            await updateSetting(currentSetting ? currentSetting.id : 0 , { ...values });
            setOpenEditSettingModal(false);
            await getAllSetting();
            Notification("success", "บันทึกข้อมูลเรียบร้อยแล้ว");
          } catch (error) {
            setOpenEditSettingModal(false);
            Notification("error", "ไม่สามารถบันทึกข้อมูลได้ คีย์ข้อมูลซ้ำ");
          }
        });
      }

    const onChangeAuto = async (e: boolean, v: any) => {
        setLoading(true);
        try {
            const body = {
                isAuto: e ? 'active' : 'inactive'
            };

            const response = await updateLottoMaster(v.id, body);
            if (response.status === 200) {
                Notification('success', 'บันทึกข้อมูลเรียบร้อย');
            } else {
                throw new Error('กรุณาลองใหม่อีกครั้ง');
            }

        } catch (error) {
            Notification('error', 'เกิดข้อผิดพลาด ' + error);
        }
        setLoading(false);
    }

    const setLottoMasterByPermissions = () => {

        if (role === 'header') {
            if (permissions !== 'null') {
                const lottoMasterPermissions = utils.getLottoPermissions(true);

                return lottoMasterPermissions.map((v: LottoMaster) => {
                    return (
                        <Space>
                            <span key={v.id} style={{ fontSize: 16 }}>{v.name}</span>
                            <Switch key={v.id} defaultChecked={v.isAuto === 'active' ? true : false} onChange={(e) => onChangeAuto(e, v)} />
                        </Space>
                    )
                });
            }
        }

        return lottoMaster.map((v: any) => {
            return (
                <Space>
                    <span key={v["id"]} style={{ fontSize: 16 }}>{v["name"]}</span>
                    <Switch key={v["id"]} defaultChecked={v.isAuto === 'active' ? true : false} onChange={(e) => onChangeAuto(e, v)} />
                </Space>
            )
        })
    }

    return (
        <MainLayout menu="setting">
            <Spin spinning={loading}>
                <PageHeader
                    title="ตั้งค่าออกผลอัตโนมัติ"
                    subTitle={<span> ออกผลอัตโนมัติโดยการจ่ายน้อยที่สุดคือ 0 บาท</span>}
                />
                <Space direction="vertical" style={{ padding: '0px 24px' }}>
                    {setLottoMasterByPermissions()}
                </Space>

                <Divider />

                <PageHeader
                    title="ตั้งค่า"
                    subTitle={<span> ตั้งค่าอื่นๆ</span>}
                />

                <Space direction="vertical" style={{ padding: '0px 24px' }}>
                    <Table
                        // bordered
                        locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }}
                        size={'small'}
                        columns={settingColumns(onEditSetting, onConfirmEditSetting)}
                        dataSource={setting}
                        rowKey={'id'}
                        pagination={{ defaultPageSize: 20, position: ['bottomRight'], showTotal: (total, range) => `${range[0]}-${range[1]} จาก ${total} ข้อมูล`, }}
                    />
                </Space>

                <Modal
                    title={'แก้ไขการตั้งค่า'}
                    maskClosable={false}
                    width={1000}
                    open={openEditSettingModal}
                    onOk={() => onConfirmEditSetting()}
                    okText={'บันทึก'}
                    onCancel={() => setOpenEditSettingModal(false)}
                    cancelText={'ยกเลิก'}
                >
                    <Form name="ninjadash-vertical-form" layout="vertical" form={formEdit}>
                        <Form.Item
                            name="key"
                            label="คีย์ข้อมูล"
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณาระบุคีย์ข้อมูล'
                                },
                            ]}
                        >
                            <Input placeholder="คีย์ข้อมูล" />
                        </Form.Item>
                        <Form.Item
                            name="value"
                            label="ค่าของข้อมูล"
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณาระบุค่าของข้อมูล'
                                },
                            ]}
                        >
                            <Input placeholder="ค่าของข้อมูล" />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label="ความหมาย"
                        >
                            <Input placeholder="ความหมายของข้อมูล" />
                        </Form.Item>
                    </Form>
                </Modal>
            </Spin>
        </MainLayout>
    )
}

export default Setting;